@tailwind base;
@tailwind components;
@tailwind utilities;

.footer {
    position: absolute;
    bottom: 0;
    left:0;
    right:0;
    text-align: center;
}

::-webkit-scrollbar {
    display: none;
}

@font-face {
    font-family: "bit";
    src: url("../public/fonts/Minecraftia-Regular.ttf");
  }

@font-face {
font-family: "pixel";
src: url("../public/fonts/I-pixel-u.ttf");
}

@font-face {
    font-family: "pixel";
    src: url("../public/fonts/Minecraft.ttf");
    }
    
